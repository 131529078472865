import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';
import ErrorContext from "../context/ErrorContext";
import AuthContext from "../context/AuthContext";
import {useHistory} from "react-router-dom";
import getFieldName from "../utils/fieldNames";
import {ensureTrailingSlash} from "../utils/misc";

function useCallDataApi(model) {
    const history = useHistory()
    const {headers, formDataHeaders} = useAuthHeader();
    const {logoutUser, updateToken, authTokens} = useContext(AuthContext)
    const {baseUrl} = useContext(ConfigContext);
    const {setSubscriptionError, setPlanError, setUnexpectedError, setNetworkError, setPermissionError, setExpectedError} = useContext(ErrorContext)

    const url = baseUrl + '/' + model + '/';

    const authenticatedAPI = axios.create({
        baseURL: url,
        headers: headers
    })

    const formDataAuthenticatedAPI = axios.create({
        baseURL: url,
        headers: formDataHeaders
    })

    const errorMap = {
        '1': () => setSubscriptionError(true),
        '2': () => setPlanError(true),
        '3': () => setPermissionError(true),
        undefined: () => setUnexpectedError(true),
    }

    const handleError = (e) => {
        console.log(e)
        if (e?.code === 'ERR_NETWORK') setNetworkError(true)
        if (e.response.status === 401) {
            logoutUser()
            return
        }
        console.log(e.response?.data)
        try {
            JSON.stringify(e.response?.data)
            const message = []
            const messages = Object.keys(e.response?.data);
            if (messages.length < 10) {
                for (let key of messages) {
                    message.push(`${getFieldName(key)}: ${e.response?.data[key]}`)
                }
                setExpectedError({error: true, message: message})
            } else {
                setUnexpectedError(true)
            }
        } catch (e) {
            errorMap[e.response?.data?.detail]()
        }
    }

    const createData = useCallback(async (data) => {
            try {
                const promise = await authenticatedAPI.post("", JSON.stringify(data))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const createDataFormData = useCallback(async (data) => {
            try {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                const promise = await formDataAuthenticatedAPI.post("", formData)
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [formDataAuthenticatedAPI]
    );

    const postData = useCallback(async (slug, data) => {
            try {
                const promise = await authenticatedAPI.post(slug, JSON.stringify(data))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const postDataFormData = useCallback(async (slug, data) => {
            try {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                const promise = await formDataAuthenticatedAPI.post(ensureTrailingSlash(slug), formData)
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [formDataAuthenticatedAPI]
    );

    const getData = useCallback(async (id = null) => {
            try {
                const promise = await authenticatedAPI.get(id);
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const getBlob = useCallback(async (id = null) => {
            try {
                const promise = await authenticatedAPI.get(id, { responseType: 'blob' })
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    const updateData = useCallback(async (id, data) => {
            try {
                const promise = await authenticatedAPI.patch(`${id}`, JSON.stringify(data))
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }

        },
        [authenticatedAPI]
    );

    const updateDataFormData = useCallback(async (id, data) => {
            try {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                const promise = await formDataAuthenticatedAPI.patch(ensureTrailingSlash(id), formData)
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }

        },
        [formDataAuthenticatedAPI]
    );

    const deleteData = useCallback(async (id) => {
            try {
                const promise = await authenticatedAPI.delete(`${id}/`)
                return promise?.data;
            } catch (error) {
                if (error.response && [401, 403].includes(error.response.status)) {
                    const newToken = await updateToken();
                    error.config.headers['Authorization'] = `Bearer ${newToken}`
                    const newResponse = await axios(error.config)
                    return newResponse?.data
                }
                handleError(error);
            }
        },
        [authenticatedAPI]
    );

    return {
        getData,
        createData,
        createDataFormData,
        updateData,
        updateDataFormData,
        deleteData,
        postData,
        postDataFormData,
        getBlob,
    };
}

export default useCallDataApi;

import {useTheme} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const SocialButton = ({socialIcon, inverted = false, onClick}) => {
    const baseTheme = useTheme()
    const theme = createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: inverted ? '#fff' : baseTheme.palette.primary.main,
                        backgroundColor: inverted ? baseTheme.palette.primary[600] : '#fff',
                        '&:hover': {
                            color: inverted ? baseTheme.palette.primary[600] : '#fff',
                        },
                    }
                }
            }
        },
    })

    return <IconButton theme={theme} onClick={onClick} aria-label="social" size="large">{socialIcon}</IconButton>
}

export default SocialButton
import {Stack} from "@mui/material";
import {useContext} from "react";
import PersonalData from "./PersonalData";
import BillingData from "./BillingData";
import PaymentData from "./PaymentData";
import QuestionData from "./QuestionData";
import ExtraItemData from "./ExtraItemData";
import OtherData from "./OtherData";
import ReservationContext from "../../context/ReservationContext";


const FillData = () => {
    const {questions, extraItems} = useContext(ReservationContext)

    return <Stack spacing={{xs: 2}}>
        <PersonalData/>
        <BillingData/>
        <PaymentData/>
        {questions?.length !== 0 && <QuestionData/>}
        {extraItems?.length !== 0 && <ExtraItemData/>}
        <OtherData/>
    </Stack>
}

export default FillData
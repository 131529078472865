import AuthBase from "../AuthBase";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    Box,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import ConfigContext from "../../context/ConfigContext";
import businessTemplate from "./BusinessTemplate";
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import hero from '../../assets/Hero section.png';
import services from '../../assets/Services section.png';
import colleagues from '../../assets/Colleagues section.png';
import contact from '../../assets/Contact section.png'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import Loading from "../../components/Loading";
import _ from "lodash";
import {isUnderMaxFileSize} from "../../utils/ValidationUtils";

const Web = () => {
    const theme = useTheme()
    const {baseUrl, MAX_IMG_SIZE} = useContext(ConfigContext)
    const [images, setImages] = useState([])
    const [business, setBusiness] = useState(businessTemplate)
    const [loading, setLoading] = useState(true)
    const [editedBusiness, setEditedBusiness] = useState(businessTemplate)
    const {
        getData: fetchHomeScreenImages,
        createDataFormData: createHomeScreenImages,
        deleteData: removeImage
    } = useCallDataApi('home-screen-image')
    const {getData: fetchBusiness, updateDataFormData: updateBusiness} = useCallDataApi('business')

    useEffect(() => {
        const getData = async () => {
            const [i, b] = await Promise.all([
                fetchHomeScreenImages('get_for_business'),
                fetchBusiness('get_business')
            ])
            if (i) setImages(i)
            if (b) setBusiness(b)
            if (b) setEditedBusiness(_.cloneDeep(b))
            setLoading(false)
        }
        getData().catch(r => console.log(r))
    }, [])


    const editBusiness = (diffList) => {
        setLoading(true)
        const data = {}

        for (let diff of diffList) {
            if (!(editedBusiness?.public_logo instanceof File) && diff === 'public_logo') continue
            data[diff] = editedBusiness[diff]
        }
        updateBusiness(`${business?.id}`, data)
            .then(b => {
                if (b) {
                    setBusiness(b)
                    setEditedBusiness(_.cloneDeep(b))
                }
            })
            .finally(() => setLoading(false))
    }

    const isDifferent = (diffList) => {
        if (!editedBusiness || !business) return false
        for (let diff of diffList) {
            if (business[diff] !== editedBusiness[diff]) {
                return false
            }
        }
        return true
    }

    const buttons = [{
        name: 'Weboldal megtekintése',
        props: {
            variant: 'contained',
            endIcon: <RemoveRedEyeRoundedIcon/>,
            onClick: () => {
                window.open(`https://${business?.subdomain}.dinobooking.hu/`)
            }
        }
    }]

    const handleImageDelete = (id) => {
        removeImage(id).then(s => setImages([...images.filter(i => i.id !== id)]))
    }

    const getPic = () => {
        if (editedBusiness?.public_logo instanceof File) return URL.createObjectURL(editedBusiness?.public_logo)
        if (editedBusiness?.public_logo?.startsWith('http')) return editedBusiness?.public_logo
        if (editedBusiness?.public_logo) return baseUrl + editedBusiness?.public_logo;
        else return ''
    }

    return <AuthBase label='Weboldal' buttons={buttons} filterButtonsMobile={buttons}>
        <div className="main">
            <div className="white-card">
                <Typography variant='subtitle1'>Weboldal típusa</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Válaszd ki a vállalkozásod számára megfelelő
                    weboldal típust</Typography>
                <div className="radio-selector" style={{marginTop: '20px'}}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={editedBusiness?.only_links ? 'only_links' : 'full_website'}
                        onChange={(event) => {
                            setEditedBusiness({...editedBusiness, only_links: event.target.value === 'only_links'})
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{padding: '32px'}} className={!editedBusiness?.only_links && 'active-radio'}>
                                    <FormControlLabel value="full_website" control={<Radio/>}
                                                      label="Teljes weboldalt szeretnék"/>
                                    <Typography sx={{color: '#536B74'}} variant='body2'>Ha ezt az opciót választod akkor
                                        egy működő weboldalt
                                        kapsz ahova feltöltheted a vállalkozásodra jellemző adatokat és könnyen
                                        kapcsolatba léphetsz ügyfeleiddel.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{padding: '32px'}} className={editedBusiness?.only_links && 'active-radio'}>
                                    <FormControlLabel value="only_links" control={<Radio/>}
                                                      label="Csak foglalási felületet szeretnék"/>
                                    <Typography sx={{color: '#536B74'}} variant='body2'>Ha ezt az opciót választod akkor
                                        csak egy foglalási
                                        felületet kapsz, aminek a linkjét beágyazhatod jelenlegi weboldaladba, így a
                                        DinoBookingon keresztül kezelheted a foglalásaidat</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </div>
                <Button endIcon={<SaveIcon/>} variant='contained'
                        sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}
                        onClick={() => editBusiness(['only_links'])} disabled={isDifferent(['only_links'])}>Módosítások
                    mentése</Button>
            </div>

            {editedBusiness?.only_links && <div className="white-card">
                <Typography variant='subtitle1'>Visszairányítási weboldal címe</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Állítsd be weboldalad címét, így ha foglalást ad le
                    valaki, erre a címre irányítsa vissza a Dinobooking. Fontos hogy az egész url ki legyen írva, pl.:
                    https://facebook.com/</Typography>
                <Stack spacing={2} sx={{marginTop: '10px'}}>
                    <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Weboldal címe</Typography>
                    <TextField variant='standard' name='nullable-redirect_url' onChange={(event) => setEditedBusiness({
                        ...editedBusiness,
                        redirect_url: event.target.value
                    })} value={editedBusiness?.redirect_url || ''}/>
                </Stack>
                <Button endIcon={<SaveIcon/>} variant='contained'
                        sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}
                        onClick={() => editBusiness(['redirect_url'])} disabled={isDifferent(['redirect_url'])}>Módosítások
                    mentése</Button>
            </div>}

            <div className="white-card">
                <Typography variant='subtitle1'>Cég adatai</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Töltsd ki vállalkozásod adatait amiket meg szeretnél
                    jeleníteni
                    weboldaladon!</Typography>
                <Grid container spacing={2} sx={{marginTop: '10px'}}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>vállalkozás
                                neve</Typography>
                            <TextField variant='standard' name='public_name' onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                public_name: event.target.value
                            })} value={editedBusiness?.public_name || ''}/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>vállalkozás publikus
                                telefonszáma</Typography>
                            <TextField variant='standard' name='nullable-public_phone' onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                public_phone: event.target.value
                            })} value={editedBusiness?.public_phone || ''}/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>vállalkozás publikus
                                e-mail címe</Typography>
                            <TextField variant='standard' name='nullable-public_email' onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                public_email: event.target.value
                            })} value={editedBusiness?.public_email || ''}/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>vállalkozás
                                címe</Typography>
                            <TextField variant='standard' name='nullable-public_address' onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                public_address: event.target.value
                            })} value={editedBusiness?.public_address || ''}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                            <Avatar src={getPic()} sx={{
                                width: '96px',
                                height: '96px',
                                background: theme.palette.primary[50],
                                border: '2px solid',
                                borderColor: theme.palette.primary[500]
                            }}/>
                            <Stack spacing={2}>
                                <Typography variant='caption' sx={{color: '#536B74'}}>Töltsd fel vállalkozásod
                                    logóját!</Typography>
                                <Button sx={{padding: '16px'}} component='label' variant='outlined'
                                        endIcon={<FileUploadRoundedIcon/>}>Logó feltöltése <input type="file"
                                                                                                  onChange={event => {
                                                                                                      const file = event.target.files[0];
                                                                                                      if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) {
                                                                                                          setEditedBusiness({
                                                                                                              ...editedBusiness,
                                                                                                              public_logo: file
                                                                                                          })
                                                                                                      }
                                                                                                      else alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)
                                                                                                      }} hidden
                                                                                                  accept='image/*'/></Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Button endIcon={<SaveIcon/>} variant='contained'
                        sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}
                        onClick={() => editBusiness(['public_name', 'public_phone', 'public_email', 'public_address', 'public_logo'])}
                        disabled={isDifferent(['public_name', 'public_phone', 'public_email', 'public_address', 'public_logo'])}>Módosítások
                    mentése</Button>
            </div>

            <div className="white-card">
                <Typography variant='subtitle1'>Alapszín kiválasztása</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Válaszd ki a vállalkozásodhoz leginkább illő
                    színt!</Typography>
                <Typography sx={{color: theme.palette.primary[500], marginTop: '20px'}}
                            variant='caption'>Színek</Typography>
                <Stack direction={{xs: 'column', sm: 'column', md: 'row'}} spacing={1} marginTop='10px'>
                    <Box onClick={() => setEditedBusiness({...editedBusiness, color: 'green'})}
                         className={editedBusiness?.color === 'green' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#5EB885'}}/>
                    <Box onClick={() => setEditedBusiness({...editedBusiness, color: 'purple'})}
                         className={editedBusiness?.color === 'purple' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#98658D'}}/>
                    <Box onClick={() => setEditedBusiness({...editedBusiness, color: 'powder'})}
                         className={editedBusiness?.color === 'powder' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#BE8C88'}}/>
                    <Box onClick={() => setEditedBusiness({...editedBusiness, color: 'blue'})}
                         className={editedBusiness?.color === 'blue' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#3D8AB4'}}/>
                    <Box onClick={() => setEditedBusiness({...editedBusiness, color: 'grey'})}
                         className={editedBusiness?.color === 'grey' ? 'active-color' : 'not-active-color'}
                         sx={{background: '#4F5869'}}/>
                </Stack>
                <Button endIcon={<SaveIcon/>} variant='contained'
                        sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}
                        onClick={() => editBusiness(['color'])} disabled={isDifferent(['color'])}>Módosítások
                    mentése</Button>
            </div>
            {!editedBusiness?.only_links && <div className="white-card">
                <Typography variant='subtitle1'>Kezdőlap</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Címnek egyszerű rövid pár szóból álló lényegretörő
                    értékajánlatot érdemes
                    megadni, amelyet az alcímben jobban kifejthetsz. (pl.: Környezettudatos kiszállítás
                    Budapesten)</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} sx={{marginTop: '10px'}}>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Címsor</Typography>
                            <TextField variant='standard' name='hero_heading' onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                hero_heading: event.target.value
                            })} value={editedBusiness?.hero_heading || ''}/>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Alcím</Typography>
                            <TextField multiline minRows={4} variant='standard' name='hero_text'
                                       onChange={(event) => setEditedBusiness({
                                           ...editedBusiness,
                                           hero_text: event.target.value
                                       })} value={editedBusiness?.hero_text || ''}/>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Képek</Typography>
                            <Grid spacing={1} container>
                                {images.map((p, i) => <Grid item xs={6} key={`home_screen_image_${i}`}>
                                    <Button onClick={() => handleImageDelete(p.id)} sx={{
                                        width: '96px',
                                        height: '96px',
                                        background: `url(${baseUrl + p.image})`,
                                        backgroundSize: 'cover',
                                        '&:hover': {background: `url(${baseUrl + p.image})`, backgroundSize: 'cover'}
                                    }}><DeleteForeverRoundedIcon/></Button>
                                </Grid>)}
                                <Grid item xs={6}><Button variant='outlined' component='label' sx={{
                                    width: '96px',
                                    height: '96px'
                                }}><AddCircleOutlinedIcon/><input onChange={event => {
                                    const file = event.target.files[0];
                                    if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) {
                                        setLoading(true)
                                        createHomeScreenImages({image: file})
                                            .then(i => setImages([...images, i]))
                                            .finally(() => setLoading(false))
                                    } else alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)

                                }} type="file" hidden accept='image/*'/></Button></Grid>
                            </Grid>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7} justifyContent='end'>
                        <img src={hero} alt="hero" style={{width: '100%'}}/>
                    </Grid>
                </Grid>
                <Button onClick={() => editBusiness(['hero_heading', 'hero_text'])}
                        disabled={isDifferent(['hero_heading', 'hero_text'])} endIcon={<SaveIcon/>} variant='contained'
                        sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}>Változtatások
                    mentése</Button>
            </div>}

            {!editedBusiness?.only_links && <div className="white-card">
                <Typography variant='subtitle1'>Szolgáltatások</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Röviden foglald össze milyen szolgáltatásokat nyújt
                    vállalkozásod ügyfeleid számára!</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} sx={{marginTop: '10px'}}>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Címsor</Typography>
                            <TextField variant='standard' name='category_heading'
                                       onChange={(event) => setEditedBusiness({
                                           ...editedBusiness,
                                           category_heading: event.target.value
                                       })} value={editedBusiness?.category_heading || ''}/>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Alcím</Typography>
                            <TextField multiline minRows={4} variant='standard' name='category_text'
                                       onChange={(event) => setEditedBusiness({
                                           ...editedBusiness,
                                           category_text: event.target.value
                                       })} value={editedBusiness?.category_text || ''}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <img src={services} alt="Szolgáltatások" style={{width: '100%'}}/>
                    </Grid>
                </Grid>
                <Button onClick={() => editBusiness(['category_heading', 'category_text'])}
                        disabled={isDifferent(['category_heading', 'category_text'])} endIcon={<SaveIcon/>}
                        variant='contained' sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}>Változtatások
                    mentése</Button>
            </div>}

            {!editedBusiness?.only_links && <div className="white-card">
                <Typography variant='subtitle1'>Kollégáink</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Röviden foglald össze kollégáid miben tudnak
                    kiemelkedőt nyújtani a konkurenciához képet!</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} sx={{marginTop: '10px'}}>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Címsor</Typography>
                            <TextField variant='standard' name='colleagues_heading'
                                       onChange={(event) => setEditedBusiness({
                                           ...editedBusiness,
                                           colleagues_heading: event.target.value
                                       })} value={editedBusiness?.colleagues_heading || ''}/>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Alcím</Typography>
                            <TextField variant='standard' multiline minRows={4} name='colleagues_text'
                                       onChange={(event) => setEditedBusiness({
                                           ...editedBusiness,
                                           colleagues_text: event.target.value
                                       })} value={editedBusiness?.colleagues_text || ''}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <img src={colleagues} alt="kollegák" style={{width: '100%'}}/>
                    </Grid>
                </Grid>
                <Button onClick={() => editBusiness(['colleagues_heading', 'colleagues_text'])}
                        disabled={isDifferent(['colleagues_heading', 'colleagues_text'])} endIcon={<SaveIcon/>}
                        variant='contained' sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}>Változtatások
                    mentése</Button>
            </div>}

            {!editedBusiness?.only_links && <div className="white-card">
                <Typography variant='subtitle1'>Kapcsolat</Typography>
                <Typography sx={{color: '#536B74'}} variant='body2'>Írj egy rövid felhívó üzenetet ügyfeled számára
                    amivel felkelted érdeklődésüket!</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Stack spacing={2} sx={{marginTop: '10px'}}>
                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Címsor</Typography>
                            <TextField onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                contact_heading: event.target.value
                            })} value={editedBusiness?.contact_heading || ''} variant='standard'
                                       name='contact_heading'/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Alcím</Typography>
                            <TextField multiline minRows={4} onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                contact_text: event.target.value
                            })} value={editedBusiness?.contact_text || ''} variant='standard' name='contact_text'/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Facebook</Typography>
                            <TextField onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                facebook: event.target.value
                            })} InputProps={{
                                startAdornment: <InputAdornment position="end">https://facebook.com/</InputAdornment>
                            }} value={editedBusiness?.facebook || ''} variant='standard' name='nullable-facebook'/>

                            <Typography variant='caption'
                                        sx={{color: theme.palette.primary[500]}}>Instagram</Typography>
                            <TextField onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                instagram: event.target.value
                            })} InputProps={{
                                startAdornment: <InputAdornment position="end">https://instagram.com/</InputAdornment>
                            }} value={editedBusiness?.instagram || ''} variant='standard' name='nullable-instagram'/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Twitter</Typography>
                            <TextField onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                twitter: event.target.value
                            })} InputProps={{
                                startAdornment: <InputAdornment position="end">https://twitter.com/</InputAdornment>
                            }} value={editedBusiness?.twitter || ''} variant='standard' name='nullable-twitter'/>

                            <Typography variant='caption' sx={{color: theme.palette.primary[500]}}>Youtube</Typography>
                            <TextField onChange={(event) => setEditedBusiness({
                                ...editedBusiness,
                                youtube: event.target.value
                            })} InputProps={{
                                startAdornment: <InputAdornment position="end">https://youtube.com/</InputAdornment>
                            }} value={editedBusiness?.youtube || ''} variant='standard' name='nullable-youtube'/>

                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <img src={contact} alt="kontakt" style={{width: '100%'}}/>
                    </Grid>
                </Grid>
                <Button
                    onClick={() => editBusiness(['contact_heading', 'contact_text', 'facebook', 'instagram', 'twitter', 'youtube'])}
                    disabled={isDifferent(['contact_heading', 'contact_text', 'facebook', 'instagram', 'twitter', 'youtube'])}
                    endIcon={<SaveIcon/>}
                    variant='contained' sx={{color: '#fff', padding: '15px 12px 15px 16px', marginTop: '20px'}}>Változtatások
                    mentése</Button>
            </div>}
        </div>


        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Web
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import React, {useState} from "react";
import {useEffect} from "react";
import {format} from "date-fns";
import {Chip, Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import useCallDataApi from "../../hooks/data";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import hu from "date-fns/locale/hu";


const SelectOnlyDate = ({reservationData, modifyTrigger, updateDate}) => {
    const today = new Date()
    const {getData, postData} = useCallDataApi('sp-reserve')

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedSlot, setSelectedSlot] = useState({})
    const [availability, setAvailability] = useState({})
    const [slots, setSlots] = useState([])
    const [month, setMonth] = useState(today.getMonth() + 1)
    const [year, setYear] = useState(today.getFullYear())

    useEffect(() => {
        updateDate(reservationData.id, formatHuman(selectedDate), selectedSlot)
    }, [modifyTrigger])

    const formatHuman = (date) => {
        return format(date, "yyyy-MM-dd")

    }

    const getSlots = () => {
        getData(`get_free_slots_for_date/?price=${reservationData.price.id}&date=${formatHuman(selectedDate)}`)
            .then(r => setSlots(r))
    }

    const getAvailability = (y, m) => {
        if (y === undefined || m === undefined) {
            y = year
            m = month
        }
        postData('monthly_availability/', {year: y, month: m, price: reservationData.price.id})
            .then(r => setAvailability(r))
    }

    useEffect(() => {
        getSlots()
        getAvailability()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    const theme = useTheme()

    const isAvailableForReserve = (date) => {
        if (availability === undefined) return false
        if (Object.keys(availability).includes(formatHuman(date))) return !availability[formatHuman(date)]
        return false
    }

    return <div className="data-container">
        <Stack direction={{xs: 'column', md: 'row'}} alignItems='center'>
            <div className="date-select-calendar">
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        disablePast={true}
                        loading={availability === undefined}
                        openTo="day"
                        shouldDisableDate={isAvailableForReserve}
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                        onMonthChange={(d) => {
                            const year = d.getFullYear()
                            const month = d.getMonth()
                            setMonth(month + 1)
                            setYear(year)
                            getAvailability(year, month + 1)
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
            <div className="data-container-slots" style={slots.length !== 0 ? {} : {display: 'flex'}}>
                {slots.length !== 0 ? <>
                        <Typography variant="subtitle2" align="center">Szabad időpontok
                            - {formatHuman(selectedDate)}</Typography>
                        <Typography variant="body2" gutterBottom align="center">Válassz szabad időpontot az alábbiak
                            közül!</Typography>
                        <Grid container spacing={1}>
                            {slots.map((s, i) => <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={4}
                                                       md={3} key={`slot_${i}`}>
                                <Chip onClick={() => {
                                    setSelectedSlot(s)
                                }} label={s.start + '-' + s.end} style={{
                                    color: selectedSlot?.start === s.start ? 'white' : theme.palette.primary.main,
                                    backgroundColor: selectedSlot?.start === s.start ? theme.palette.primary['400'] : theme.palette.primary['20'],
                                    fontWeight: '600',
                                }}/>
                            </Grid>)}
                        </Grid>
                    </>
                    : <div style={{alignSelf: "center"}}>
                        <Typography variant="subtitle1" align="center">Erre a napra nincsenek szabad
                            időpontok!</Typography>
                        <Typography variant="body2" gutterBottom align="center">Válassz másik időpontot!</Typography>
                    </div>}
            </div>
        </Stack>
    </div>
}

export default SelectOnlyDate
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

const DetailDrawer = ({open, setOpen, children}) => {

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    const list = () => (
        <Box sx={{width: {xs: '100vw', md: '75vw', lg: '50vw'}}}
             role="presentation"
             onKeyDown={toggleDrawer(false)}
        >
            {children}
        </Box>
    );

    return <Drawer
        anchor={'right'}
        open={open}
        onClose={toggleDrawer(false)}
    >
        {list()}
    </Drawer>
}

export default DetailDrawer
import {Box, Button, Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import ErrorHandlingTextField, {validateRequired} from "../../../components/ErrorHandlingTextField";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

const BillingoDetails = ({dataHandler, existingData={}}) => {
    const theme = useTheme()
    const [data, setData] = useState({
        name: '',
        block_id: '',
        bank_account_id: '',
        api_key: '',
        ...existingData
    })
    const [errors, setErrors] = useState({
        name: false,
        block_id: false,
        bank_account_id: false,
        api_key: false,
    })
    const isFilled = () => Object.values(data).every(x => x !== '')
    const isError = () => Object.values(errors).every(d => d === false)

    const proceed = () => dataHandler(data)

    return <>
        <Grid container spacing={2} marginTop={2} marginBottom={2}>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>Név</Typography>
                    <ErrorHandlingTextField
                        check={(v) => setErrors({...errors, name: v})}
                        validators={[validateRequired]}
                        value={data?.name}
                        onValChange={e => setData({...data, name: e.target.value})}
                        variant='standard'/>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>Blokk id</Typography>
                    <ErrorHandlingTextField
                        check={(v) => setErrors({...errors, block_id: v})}
                        validators={[validateRequired]}
                        value={data?.block_id}
                        onValChange={e => setData({...data, block_id: e.target.value})}
                        variant='standard'/>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>Bankszámla id</Typography>
                    <ErrorHandlingTextField
                        check={(v) => setErrors({...errors, bank_account_id: v})}
                        validators={[validateRequired]}
                        value={data?.bank_account_id}
                        onValChange={e => setData({...data, bank_account_id: e.target.value})}
                        variant='standard'/>
                </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>API kulcs</Typography>
                    <ErrorHandlingTextField
                        check={(v) => setErrors({...errors, api_key: v})}
                        validators={[validateRequired]}
                        value={data?.api_key} type='password'
                        onValChange={e => setData({...data, api_key: e.target.value})}
                        variant='standard'/>
                </Stack>
            </Grid>
        </Grid>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <Button
                variant='contained'
                sx={{color: '#fff', padding: '15px 12px 15px 16px'}}
                endIcon={<SaveRoundedIcon/>}
                onClick={proceed}
                disabled={!isFilled() || !isError()}
            >Módosítások mentése és kapcsolat tesztelése</Button>
        </Box>
    </>
}

export default BillingoDetails
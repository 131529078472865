import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import greenTheme from "./components/themes/GreenTheme";

import NotFound from "./pages/404";
import Login from "./pages/authentication/Login";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import Dashboard from "./pages/dashboard/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";
import Web from "./pages/website/Web";
import Categories from "./pages/category/Categories";
import Services from "./pages/category/service/Services";
import ServiceDetail from "./pages/category/service/ServiceDetail";
import Colleagues from "./pages/colleague/Colleagues";
import OpeningHours from "./pages/opening-hours/OpeningHours";
import Notifications from "./pages/notification/Notifications";
import Settings from "./pages/settings/Settings";
import Reservations from "./pages/reservation/Reservations";
import ColleagueDetail from "./pages/colleague/ColleagueDetail";
import Register from "./pages/authentication/Registration";
import SuccessfulRegistration from "./pages/authentication/SuccessfulRegistration";
import RegisterExisting from "./pages/authentication/ExistingRegistration";
import CardRegistration from "./pages/authentication/CardRegistration";
import PaymentOver from "./pages/authentication/PaymentOver";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import NewPassword from "./pages/authentication/NewPassword";
import {ErrorProvider} from "./context/ErrorContext";
import ActivationLogin from "./pages/authentication/ActivationLogin";
import ExtraItems from "./pages/extra-items/ExtraItems";
import ExtraItemDetail from "./pages/extra-items/ExtraItemDetail";
import {AuthConfigProvider} from "./context/AuthConfigContext";
import SmsPaymentOver from "./pages/authentication/SmsPaymentOver";


function App() {

    return <ThemeProvider theme={greenTheme}>
        <ConfigProvider>
            <ErrorProvider>
                <Router>
                    <AuthProvider>
                        <AuthConfigProvider>
                            <Switch>
                                <PrivateRoute path="/" manager component={Dashboard} exact/>

                                <PrivateRoute path="/web" manager component={Web} exact/>

                                <PrivateRoute path="/categories" manager component={Categories} exact/>
                                <PrivateRoute path="/service/:id" manager component={Services} exact/>
                                <PrivateRoute path="/service-detail/:id" manager component={ServiceDetail} exact/>

                                <PrivateRoute path="/extra-items" manager component={ExtraItems} exact/>
                                <PrivateRoute path="/extra-items/:id" manager component={ExtraItemDetail} exact/>

                                <PrivateRoute path="/colleagues" component={Colleagues} exact/>
                                <PrivateRoute path="/colleague/:id" component={ColleagueDetail} exact/>

                                <PrivateRoute path="/opening-hours" manager component={OpeningHours} exact/>

                                <PrivateRoute path="/notifications" manager component={Notifications} exact/>

                                <PrivateRoute path="/settings" manager component={Settings} exact/>

                                <PrivateRoute path="/reservations" component={Reservations} exact/>
                                <Route path="/login" component={Login} exact/>
                                <Route path="/activation-login" component={ActivationLogin} exact/>
                                <Route path="/forgot-password" component={ForgotPassword} exact/>
                                <Route path="/new-password/:slug" component={NewPassword} exact/>
                                <Route path="/register" component={Register} exact/>
                                <Route path="/register-existing/:slug" component={RegisterExisting} exact/>
                                <Route path="/card-registration" component={CardRegistration} exact/>
                                <Route path="/payment-over" component={PaymentOver} exact/>
                                <Route path="/sms-payment-over" component={SmsPaymentOver} exact/>

                                <Route path="/success" component={SuccessfulRegistration} exact/>
                                <Route component={NotFound}/>
                            </Switch>
                        </AuthConfigProvider>
                    </AuthProvider>
                </Router>
            </ErrorProvider>
        </ConfigProvider>
    </ThemeProvider>
}

export default App;
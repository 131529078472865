const serviceTemplate = {
    category: 1,
    description: "",
    id: 1,
    image: "",
    is_accept_automatically: true,
    is_pay_cash_or_card: true,
    is_pay_online: true,
    is_public: true,
    length: 1,
    name: "",
    automatic_invoice: false,
    mandatory_billing_details: 'optional',
    accept_after: 60,
    accept_before: 0,
    is_pay_advance: false,
    advance_amount: 0,
}

export default serviceTemplate
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {DeleteRounded} from "@mui/icons-material";
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';

const TimeRangePicker = ({startTime, endTime, editing, deleting, day, idx }) => {
  const theme = useTheme()
  const [edit, setEdit] = useState(false)
  const [st, setSt] = useState(startTime)
  const [et, setEt] = useState(endTime)

  return <Box sx={{background: theme.palette.primary[20], height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid', borderColor: theme.palette.primary[200], borderRadius: '16px', padding: '8px 16px'}}>
    {edit ?
        <>
          <input type='time' style={{width: 'fit-content'}} value={st} onChange={(event) => setSt(event.target.value)}/>
          -
          <input type='time' style={{width: 'fit-content'}} value={et} onChange={(event) => setEt(event.target.value)}/>
        </>
        :
        <>
          <Typography variant='button' color={theme.palette.primary[700]}>{st}</Typography>
          -
          <Typography variant='button' color={theme.palette.primary[700]}>{et}</Typography>
        </>
    }
    <DoneOutlineRoundedIcon sx={{display: edit ? "block" : "none", cursor: 'pointer', color: theme.palette.primary[500], fontSize: '18px'}} onClick={() => {
      if (edit) editing(st, et, day, idx)
      setEdit(p => !p)
    }}/>
    <EditIcon onClick={() => {
      if (edit) editing(st, et, day, idx)
      setEdit(p => !p)
    }} sx={{display: edit ? "none" : "block", cursor: 'pointer', color: theme.palette.primary[500], fontSize: '18px'}}/>
    <DeleteRounded onClick={() => deleting(day, idx)} sx={{cursor: 'pointer', color: theme.palette.primary[500], fontSize: '18px'}}/>
  </Box>
}

export default TimeRangePicker
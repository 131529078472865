import {Stack} from "@mui/material";
import {useContext, useState} from "react";
import Typography from "@mui/material/Typography";
import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import {validateMaxFileSize, validateMaxLength, validateRequired} from "../../components/formValidation/validators";
import CustomTextField from "../../components/formValidation/CustomTextField";
import CustomImageField from "../../components/formValidation/CustomImageField";
import ConfigContext from "../../context/ConfigContext";

const CategoryData = ({reference, obj}) => {
    const {MAX_IMG_SIZE} = useContext(ConfigContext)

    const [data, setData] = useState({
        name: obj?.name || '',
        description: obj?.description || '',
        picture: obj?.picture || '',
    })

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        picture: false,
    })

    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)],
        description: [validateRequired, value => validateMaxLength(255, value)],
        picture: [validateRequired, file => validateMaxFileSize(file, MAX_IMG_SIZE)]
    }

    return <ErrorHandlingForm reference={reference} errors={errors} data={data} setData={setData} setErrors={setErrors} validators={validators}>
        <Stack spacing={2}>
            <Typography variant='caption' color='primary.main'>Név*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='name'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Kategória rövid leírása*</Typography>
            <CustomTextField
                validators={validators}
                setErrors={setErrors}
                errors={errors}
                data={data}
                setData={setData}
                name='description'
                variant='standard'
                multiline
                minRows={5}
            />
            <Typography variant='caption' color='primary.main'>Kategória képe*</Typography>
            <CustomImageField
                data={data}
                setData={setData}
                name='picture'
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
        </Stack>
    </ErrorHandlingForm>
}

export default CategoryData
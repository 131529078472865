const userTemplate = {
    id: 1,
    name: '',
    title: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    profile_picture: ''
}

export default userTemplate
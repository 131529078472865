import Base from "./Base";
import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, TextField, useTheme} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import IconButton from "@mui/material/IconButton";
import ErrorHandlingTextField, {
    validateMinLength,
    validatePassword,
    validateRequired
} from "../../components/ErrorHandlingTextField";
import ErrorHandlingPhoneInput, {
    validatePhoneNumber,
    validatePhoneRequired
} from "../../components/ErrorHandlingPhoneInput";


const RegisterExisting = () => {
    const theme = useTheme()
    const {registerExistingUser} = useContext(AuthContext);
    const [passwordVisible, setPasswordVisible] = useState(false)
    const {slug} = useParams()
    const [data, setData] = useState({
        last_name: '',
        first_name: '',
        password: '',
        password_again: '',
        phone_number: '+36',
        slug: slug
    })

    const [errors, setErrors] = useState({
        last_name: false,
        first_name: false,
        password: false,
        password_again: false,
        phone_number: false,
    })

    const isFilled = () => Object.values(data).every(x => x !== '')
    const isError = () => Object.values(errors).every(x => x === false)

    const buttonText = () => {
        if (!isFilled()) return 'Adj meg minden adatot a regisztrációhoz'
        if (!isError()) return 'Javítsd a pirossal jelzett hibákat'
        return 'Regisztráció'
    }

    return <Base>
        <div className="login-container">
            <div className="login-card">

                <form onSubmit={e => {
                    e.preventDefault()
                    registerExistingUser(data)
                }}>
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color={theme.palette.primary[600]}>Regisztráció</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Stack spacing={1} direction='row' sx={{width: '100%'}}>
                                <Stack spacing={1} sx={{width: '100%'}}>
                                    <Typography variant="caption" color={theme.palette.primary[500]}>Vezetéknév*</Typography>
                                    <ErrorHandlingTextField check={(v) => setErrors({...errors, last_name: v})} validators={[validateRequired]} value={data?.last_name} onValChange={e => setData({...data, last_name: e.target.value})} name="last_name" variant='standard'/>
                                </Stack>
                                <Stack spacing={1} sx={{width: '100%'}}>
                                    <Typography variant="caption" color={theme.palette.primary[500]}>Keresztnév*</Typography>
                                    <ErrorHandlingTextField check={(v) => setErrors({...errors, first_name: v})} validators={[validateRequired]} value={data?.first_name} onValChange={e => setData({...data, first_name: e.target.value})} name="first_name" variant='standard'/>
                                </Stack>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant="caption" color={theme.palette.primary[500]}>Telefonszám*</Typography>
                                <ErrorHandlingPhoneInput
                                    defaultCountry='hu'
                                    variant='standard'
                                    validators={[validatePhoneNumber, validatePhoneRequired]}
                                    value={data?.phone_number}
                                    check={(v) => setErrors({...errors, phone_number: v})}
                                    onValChange={e => setData({...data, phone_number: e})}/>
                            </Stack>
                            <Typography variant="caption" color={theme.palette.primary[500]}>Jelszó*</Typography>
                            <ErrorHandlingTextField
                                value={data?.password}
                                check={(v) => setErrors({...errors, password: v})}
                                onValChange={e => setData({...data, password: e.target.value})}
                                name="password"
                                validators={[validateRequired, (v) => validateMinLength(8, v), validatePassword]}
                                type={passwordVisible ? "text" : "password"}
                                variant='standard'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        edge="end"
                                    >
                                        {passwordVisible ? <VisibilityOffRoundedIcon/> : <RemoveRedEyeRoundedIcon/>}
                                    </IconButton>
                                    </InputAdornment>
                                }}/>
                            <Typography variant="caption" color={theme.palette.primary[500]}>Jelszó mégegyszer*</Typography>
                            <ErrorHandlingTextField
                                value={data?.password_again}
                                validators={[validateRequired]}
                                check={(v) => setErrors({...errors, password_again: v})}
                                onValChange={e => setData({...data, password_again: e.target.value})}
                                name="password_again" type={passwordVisible ? "text" : "password"}
                                variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end"><IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                >
                                    {passwordVisible ? <VisibilityOffRoundedIcon/> : <RemoveRedEyeRoundedIcon/>}
                                </IconButton>
                                </InputAdornment>
                            }}/>
                        </Stack>
                        <Link to="/" style={{color: theme.palette.primary[600]}}>Elfelejtetted a jelszavad?</Link>
                        <Button variant="contained" type="submit" disabled={!isFilled() || !isError()}>{buttonText()}</Button>

                        <Link to="/login" style={{color: theme.palette.primary[600]}}>Van már felhasználói fiókod? Jelentkezz be!</Link>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default RegisterExisting
import Footer from "../components/Footer";
import ResponsiveDrawer from "../components/AuthDrawer";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {useContext, useEffect} from "react";
import AuthContext from "../context/AuthContext";


const AuthBase = ({children, label, buttons, filterButtons, filterButtonsMobile}) => {
    const {isSetupComplete} = useContext(AuthContext)
    const history = useHistory()
    const currentPath = window.location.pathname
    const allowedPaths = ['/payment-over', '/card-registration']

    useEffect(() => {

        if (!isSetupComplete() && !allowedPaths.includes(currentPath)) {
            history.push('/card-registration')

        }
    }, []);

    return <>
        <ResponsiveDrawer label={label} buttons={buttons} filterButtons={filterButtons} filterButtonsMobile={filterButtonsMobile}>
            <div className="base-container">
                {children}
            </div>
            <Footer/>
        </ResponsiveDrawer>
    </>
}

export default AuthBase
import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children, manager=false, ...rest }) => {
    const { user, role } = useContext(AuthContext);
    if (manager && role === 'employee') return <Redirect to="/reservations" />
    return <Route {...rest}>{!user ? <Redirect to="/login" /> : children}</Route>;
};

export default PrivateRoute;
import {createContext} from "react";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {


    const contextData = {
        baseUrl: process.env.REACT_APP_BASE_URL,
        ownUrl: process.env.REACT_APP_OWN_URL,
        MAX_IMG_SIZE: 2,
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}
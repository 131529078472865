import sadDino from '../assets/dinos/035-crying.png'
import Typography from "@mui/material/Typography";
import Base from "./authentication/Base";

const NotFound = () => {

    return <Base>
        <div className="not-found-container">
            <Typography variant="headline2">Az oldal nem található!</Typography>
            <img src={sadDino} alt="sad dino" style={{width: '20%'}}/>
        </div>
    </Base>

}

export default NotFound
import {TextField} from "@mui/material";
import {useState} from "react";

const ErrorHandlingTextField = ({validators, onValChange, check, ...rest}) => {
    const [error, setError] = useState('')

    const validate = (val) => {
        setError('')
        for (let validator of validators) {
            const validatedResult = validator(val)
            if (!validatedResult.result) {
                if (check) check(true)
                return setError(validatedResult.error)
            }
        }
        if (check) check(false)
    }

    return <TextField error={Boolean(error)} helperText={error} {...rest} onChange={e => {
        onValChange(e)
        validate(e.target.value)
    }}/>
}

const validateChars = (value) => {
    const res = !!/^[a-z]+$/.test(value)
    return {
        result: res,
        error: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)'
    }
}

const validateMinLength = (minLength, value) => {
    const res = value.length > minLength
    return {
        result: res,
        error: `Legalább ${minLength + 1} karakter hosszú kell hogy legyen`
    }
}

const validateMaxLength = (maxLength, value) => {
    const res = value.length < maxLength
    return {
        result: res,
        error: `Legfeljebb ${maxLength} karakter hosszú lehet`
    }
}

const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const res = emailRegex.test(value);
    return {
        result: res,
        error: 'Nem érvényes e-mail cím'
    }
}

const validateVatNumber = (value) => {
    const emailRegex = /^\d{8}-\d-\d{2}$/;
    const res = emailRegex.test(value);
    return {
        result: res,
        error: 'Nem érvényes adószám, (xxxxxxxx-y-zz)'
    }
}

const validateNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        result: res,
        error: 'Csak számokat tartalmazhat'
    }
}

const validateRequired = (value) => {
    return {
        result: Boolean(value),
        error: 'Nem maradhat üresen'
    }
}

const validateEqualsPassword = (equal, value) => {
    return {
        result: equal === value,
        error: 'Nem egyezik meg a két jelszó'
    }
}

const validatePassword = (value) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isValid = hasNumber.test(value) && hasSpecialChar.test(value)
    return {
        result: isValid,
        error: 'Tartalmaznia kell egy speciális karaktert (!@#$%^&*(),.?":{}|<>) és egy számot'
    }
}

export default ErrorHandlingTextField
export {validateChars, validateMinLength, validateEmail, validateRequired, validateEqualsPassword, validatePassword, validateMaxLength, validateNumber, validateVatNumber}
import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = ({initialValue, setter, fieldName}) => {
    const editorRef = useRef(null);

    const getContent = () => {
        if (editorRef.current) {
            return editorRef.current.getContent()
        }
        return ''
    };

    return (
        <>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={initialValue}
                onChange={() => setter(fieldName, getContent())}
                init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>
    );
}

export default TinyEditor
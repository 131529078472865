const fieldNames = {
    country: 'Ország',
    address: 'Cím',
    state: 'Megye',
    zip: 'Irányítószám',
    city: 'Város',
}


const getFieldName = (uid) => {
    if (Object.keys(fieldNames).includes(uid)) return fieldNames[uid]
    return uid
}

export default getFieldName
import Base from "./Base";
import Typography from "@mui/material/Typography";
import {Stack, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import ErrorHandlingTextField, {validateEmail} from "../../components/ErrorHandlingTextField";


const ForgotPassword = () => {
    const theme = useTheme()
    const {forgotPassword} = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)

    const isFilled = () => Boolean(email)
    const isError = () => !error
    const buttonText = () => {
        if (!isFilled()) return 'Töltsd ki a mezőt'
        if (!isError()) return 'Javítsd a pirossal jelzett hibákat'
        return 'Küldés'
    }

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <form onSubmit={e => {
                    e.preventDefault()
                    forgotPassword({email: email})
                }}>
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color={theme.palette.primary[600]}>Elfelejtett jelszó</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color={theme.palette.primary[500]}>E-mail cím</Typography>
                            <ErrorHandlingTextField check={(e) => setError(e)} validators={[validateEmail]} value={email} onValChange={e => setEmail(e.target.value)} name="email" variant='standard'/>
                        </Stack>
                        <Button variant="contained" disabled={!isFilled()||!isError()} type="submit">{buttonText()}</Button>
                        <Link to="/login" style={{color: theme.palette.primary[600]}}>Bejelentkezés</Link>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default ForgotPassword
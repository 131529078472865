

const validateChars = (value) => {
    const res = !!/^[a-z]+$/.test(value)
    return {
        valid: res,
        error: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)'
    }
}

const validateMinLength = (minLength, value) => {
    const res = value.length > minLength
    return {
        valid: res,
        error: `Legalább ${minLength + 1} karakter hosszú kell hogy legyen`
    }
}

const validateMaxLength = (maxLength, value) => {
    const res = value.length < maxLength
    return {
        valid: res,
        error: `Legfeljebb ${maxLength} karakter hosszú lehet`
    }
}

const validateEmail = (value) => {
    if (!value) return {
        valid: true,
        error: 'Nem kötelezö mező'
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: 'Nem érvényes e-mail cím'
    }
}

const validateVatNumber = (value) => {
    if (!value) return {
        valid: true,
        error: 'Nem kötelezö mező'
    }
    const emailRegex = /^\d{8}-\d-\d{2}$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: 'Nem érvényes adószám, (xxxxxxxx-y-zz)'
    }
}

const validateNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: res || !value,
        error: 'Csak számokat tartalmazhat'
    }
}

const validateNonNegativeNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: res && parseInt(value) > 0,
        error: 'Nem lehet negatív szám vagy 0'
    }
}

const validateDivisibleBy = (value, number) => {
    return {
        valid: parseInt(value) % number === 0 || !value,
        error: `Nem osztható ezzel: ${5}`
    }
}

const validateRequired = (value) => {
    return {
        valid: Boolean(value),
        error: 'Nem maradhat üresen'
    }
}

const validateEqualsPassword = (equal, value) => {
    return {
        valid: equal === value,
        error: 'Nem egyezik meg a két jelszó'
    }
}

const validateDate = (value) => {
    const isValidDate = (date) => {
        if (typeof date === 'string') {
            const parsedDate = new Date(date);
            // Check if the parsed date is valid and if the original string was a valid date format
            return !isNaN(parsedDate) && parsedDate.toISOString().startsWith(date);

        } else if (date instanceof Date) {
            return !isNaN(date);
        }
        return false;
    };
    return {
        valid: isValidDate(value),
        error: 'Helytelen dátum'
    }
}


const validatePassword = (value) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isValid = hasNumber.test(value) && hasSpecialChar.test(value)
    return {
        valid: isValid,
        error: 'Tartalmaznia kell egy speciális karaktert (!@#$%^&*(),.?":{}|<>) és egy számot'
    }
}

const validateMaxFileSize = (file, size) => {
    return {
        valid: file.size <= size * 1000 * 1000 || !file || typeof file === 'string',
        error: `A file nem lehet nagyobb mint ${size} MB`
    }
}

export {
    validateChars,
    validateMinLength,
    validateEmail,
    validateRequired,
    validateEqualsPassword,
    validatePassword,
    validateMaxLength,
    validateNumber,
    validateVatNumber,
    validateDate,
    validateNonNegativeNumber,
    validateMaxFileSize,
    validateDivisibleBy
}
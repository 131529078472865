import ErrorHandlingForm from "../../components/formValidation/ErrorHandlingForm";
import {validateMaxLength, validateRequired} from "../../components/formValidation/validators";
import {useState} from "react";
import CustomTextField from "../../components/formValidation/CustomTextField";
import {Stack, Typography, useTheme} from "@mui/material";

const ApiKeyData = ({reference, obj}) => {
    const theme = useTheme()
    const validators = {
        name: [validateRequired, value => validateMaxLength(255, value)]
    }
    const [data, setData] = useState({
        name: obj?.name || ''
    })
    const [errors, setErrors] = useState({
        name: false
    })
    return <ErrorHandlingForm reference={reference} validators={validators} setData={setData} data={data} setErrors={setErrors} errors={errors}>
        <Stack spacing={2}>
            <Typography variant='caption' color={theme.palette.primary[500]}>Név</Typography>
            <CustomTextField
                errors={errors}
                setErrors={setErrors}
                data={data}
                setData={setData}
                validators={validators}
                name='name'
                variant='standard'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default ApiKeyData
import Base from "./Base";
import {Stack, useTheme} from "@mui/material";
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";


const SuccessfulRegistration = () => {
    const theme = useTheme()
    const history = useHistory()
    
    return <Base>
        <div className="login-container">
            <div className="login-card">
                <Stack spacing={2} alignItems='center'>
                    <EmailRoundedIcon sx={{fontSize: 100, color: theme.palette.primary[500]}}/>
                    <Typography variant='headline6' color={theme.palette.primary[600]}>E-mail cím megerősítése</Typography>
                    <Typography variant='body1' color='#38474D'>E-mailt küldtünk a megadott címre, amiben található
                        linkkel végletesítheted regisztrációdat!</Typography>
                    <Button variant='outlined' onClick={() => history.push('/')}>Vissza a Kezdőlapra</Button>
                </Stack>
            </div>
        </div>
    </Base>
}

export default SuccessfulRegistration
import {Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import greenLogo from "../assets/logos/DinoBooking_logo_oldalt_felirat_01.png"
import simpleLogo from '../assets/simplepay_200x50.png'
import {HashLink} from "react-router-hash-link";
import SocialButton from "./SocialButton";


const DinoFooter = () => {
    const theme = useTheme()

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const primaryColor = {color: theme.palette.primary[700]}

    return <div className="dino-footer">
        <div className="dino-footer-content">
            <Grid container spacing={12}>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}} style={{marginBottom: '20px'}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Kontakt</Typography>
                        <Typography variant="buttonNormal" style={primaryColor}>3300, Eger Kossuth Lajos Utca 6.</Typography>
                        <Typography variant="button" style={primaryColor}>+36 30 380 53 40</Typography>
                        <Typography variant="button" style={primaryColor}>admin@dinobooking.hu</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <SocialButton onClick={() => window.open('https://www.facebook.com/dinobooking', '_blank')} inverted socialIcon={<FacebookOutlinedIcon/>}/>
                        <SocialButton onClick={() => window.open('https://instagram.com/dinobooking', '_blank')} inverted socialIcon={<InstagramIcon/>}/>
                        <SocialButton onClick={() => window.open('https://youtube.com/@DinoBooking', '_blank')} inverted socialIcon={<YouTubeIcon/>}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Szekciók</Typography>
                        <HashLink to="/" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>Kezdőlap</Typography>
                        </HashLink>
                        <HashLink to="/login" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>Bejelentkezés</Typography>
                        </HashLink>
                        <HashLink to="/register" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>Regisztráció</Typography>
                        </HashLink>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>Dokumentumok</Typography>
                        <Typography variant="buttonNormal"><a style={primaryColor} target='_blank' rel="noreferrer" href="https://dinobooking.hu/adatvedelmi-tajekoztato">Adatvédelmi tájékoztató</a></Typography>
                        <Typography variant="buttonNormal"><a style={primaryColor} target='_blank' rel="noreferrer" href="https://dinobooking.hu/aszf">Általános Szerződési Feltételek</a></Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <div className="dino-footer-container__own-logo">
                        <img src={greenLogo} alt="Dinobooking logo" width="150px"/>
                        <img onClick={() => window.location.href = 'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'} src={simpleLogo} alt="SimplePay logo" style={{cursor: 'pointer'}} width="150px"/>
                    </div>
                </Grid>
            </Grid>

        </div>
    </div>

}

export default DinoFooter
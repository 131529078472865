import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import React, {useContext, useState} from "react";
import {useEffect} from "react";
import {Box, Chip, Grid, useTheme} from "@mui/material";
import {useHistory} from "react-router-dom";
import {encode} from 'js-base64';
import Typography from "@mui/material/Typography";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";
import {formatHuman} from "../../utils/dateUtils";
import useCallDataApi from "../../hooks/data";
import ReservationContext from "../../context/ReservationContext";
import Loading from "../Loading";


const Slot = ({time, ...rest}) => {
    const theme = useTheme()
    return <Chip
        {...rest}
        label={time.start + '-' + time.end}
        style={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary['20'],
            fontWeight: '600',
        }}/>
}

const NoAvailableTime = () => <Box sx={{width: '100%', justifyContent: 'center', alignItems: 'center', alignSelf: 'center'}}>
    <Typography variant="subtitle1" align="center">Erre a napra nincsenek szabad időpontok!</Typography>
    <Typography variant="body2" gutterBottom align="center">Válassz másik időpontot!</Typography>
</Box>


const AvailableTime = ({slots, proceed, selectedDate}) => {
    return <>
        <Typography variant="subtitle2" align="center">Szabad időpontok - {formatHuman(selectedDate)}</Typography>
        <Typography variant="body2" gutterBottom align="center">Válassz szabad időpontot az alábbiak közül!</Typography>
        <Grid container spacing={1}>
            {slots.map((s, i) => <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={4} xl={4} key={`slot_${i}`}>
                <Slot time={s} onClick={() => proceed(s)}/>
            </Grid>)}
        </Grid>
    </>
}


const SelectDate = () => {
    const today = new Date()
    const {getData: fetchData, postData} = useCallDataApi('sp-reserve')
    const {next, selected} = useContext(ReservationContext)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [availability, setAvailability] = useState({})
    const [slots, setSlots] = useState([])
    const [month, setMonth] = useState(today.getMonth() + 1)
    const [year, setYear] = useState(today.getFullYear())
    const [loading, setLoading] = useState(false)

    const toUriSafe = (s) => {
        let encoded = encode(formatHuman(selectedDate) + '|' + s.start + '|' + s.end);
        return encoded.replace('==', '');
    }

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const [s, a] = await Promise.all([
                fetchData(`get_free_slots_for_date/?price=${selected?.price?.id}&date=${formatHuman(selectedDate)}`),
                postData('monthly_availability/', {year: year, month: month, price: selected?.price?.id})
            ])
            if (s) setSlots(s)
            if (a) setAvailability(a)
        }
        if (selectedDate && selected?.price) getData()
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selected?.price])

    const isAvailableForReserve = (date) => {
        if (availability === undefined) return false
        if (Object.keys(availability).includes(formatHuman(date))) return !availability[formatHuman(date)]
        return false
    }

    const proceed = (d) => next({...selected, date: toUriSafe(d)})

    const monthChange = (d) => {
        setLoading(true)
        const selectedMonth = d.getMonth() + 1
        const selectedYear = d.getFullYear()
        setMonth(selectedMonth)
        setYear(selectedYear)
        postData('monthly_availability/', {year: selectedYear, month: selectedMonth, price: selected?.price?.id})
            .then(r => setAvailability(r.data))
            .finally(() => setLoading(false))
    }

    return <div className="data-container">
        <div className="date-select-container">
            <div className="date-select-calendar">
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        disablePast
                        openTo="day"
                        shouldDisableDate={isAvailableForReserve}
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        onMonthChange={(d) => monthChange(d)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
            <div className="data-container-slots" style={slots.length !== 0 ? {} : {display: 'flex'}}>
                {slots.length !== 0 ? <AvailableTime slots={slots} proceed={proceed} selectedDate={selectedDate}/>: <NoAvailableTime/>}
            </div>
        </div>

        <Loading isLoading={loading}/>
    </div>
}

export default SelectDate
import {
    hasValue,
    isNumber,
    tooLong,
    isBoolean,
    isDividable,
    passAnyway,
    isGreater
} from "../../../utils/ValidationUtils";

const serviceSchema = {
    category: value => [hasValue(value) , isNumber(value)],
    description: value => [tooLong(value, 256)],
    id: value => [isNumber(value) , hasValue(value)],
    image: value => [passAnyway(value)],
    is_accept_automatically: value => [isBoolean(value)],
    is_pay_cash_or_card: value => [isBoolean(value)],
    is_pay_online: value => [isBoolean(value)],
    is_public: value => [isBoolean(value)],
    length: value => [hasValue(value) , isDividable(value, 5), isGreater(value, 0)],
    name: value => [tooLong(value, 256) , hasValue(value)],
}

export default serviceSchema
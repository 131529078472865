import AuthBase from "../AuthBase";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    Avatar, Box,
    Checkbox,
    FormControlLabel,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Stack,
} from "@mui/material";
import {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import DinoDataGrid from "../../components/DataGrid";
import Typography from "@mui/material/Typography";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import DetailDrawer from "../../components/DetailDrawer";
import ReservationDetail from "./ReservationDetail";
import DinoDialog from "../../components/Dialog";
import DialogDetail from "./DialogDetail";
import Button from "@mui/material/Button";
import Calendar from "../../components/calendar/Calendar";
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import ReserveBase from "../../components/reserve/ReserveBase";
import SelectOnlyDate from "../../components/reserve/SelectOnlyDate";
import Loading from "../../components/Loading";
import thousandSeparator from "../../utils/numberUtils";
import {ReservationProvider} from "../../context/ReservationContext";
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";


const Reservations = () => {
    const snackbar = useSnackbar()
    const [reservations, setReservations] = useState([])
    const [modifyTrigger, setModifyTrigger] = useState(0)
    const [displayReservations, setDisplayReservations] = useState([])
    const [detailView, setDetailView] = useState(false)
    const [reservation, setReservation] = useState({})
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [listView, setListView] = useState(true)
    const [onlyPending, setOnlyPending] = useState(false)
    const [reservationStatus, setReservationStatus] = useState('accepted')
    const [newReservationOpen, setNewReservationOpen] = useState(false)
    const [modifyTimeOpen, setModifyTimeOpen] = useState(false)
    const [loading, setLoading] = useState({fetch: true, filter: false})
    const {
        getData: fetchReservations,
        getBlob: fetchBlob,
        deleteData: destroyReservation,
        postData: postReservation
    } = useCallDataApi('admin-reservation')
    const {getData: fetchAdminReservations} = useCallDataApi('admin-reservation')

    const buttons = [{
        name: 'Új foglalás hozzáadása',
        props: {
            variant: 'contained',
            endIcon: <AddCircleOutlineIcon/>,
            onClick: () => setNewReservationOpen(true)
        }
    },
    //     {
    //     name: 'Időpont lezárása',
    //     props: {
    //         variant: 'contained',
    //         endIcon: <EventBusyRoundedIcon/>,
    //         onClick: () => setNewReservationOpen(true)
    //     }
    // }
    ]

    const filterButtons = [{
            name: 'Aktív foglalások', props: {
                variant: reservationStatus === 'accepted' ? 'contained' : 'text',
                onClick: () => setReservationStatus('accepted')
            }
        }, {
            name: 'Korábbi foglalások', props: {
                variant: reservationStatus === 'accepted' ? 'text' : 'contained',
                onClick: () => setReservationStatus('rejected')
            }
        }
    ]

    const filterButtonsMobile = [{
            name: 'Aktív', props: {
                variant: reservationStatus === 'accepted' ? 'contained' : 'text',
                size: 'small',
                onClick: () => setReservationStatus('accepted')
            }
        }, {
            name: 'Korábbi', props: {
                variant: reservationStatus === 'accepted' ? 'text' : 'contained',
                size: 'small',
                onClick: () => setReservationStatus('rejected')
            }
        }
    ]

    useEffect(() => {
        setLoading(prev => ({...prev, fetch: true}))
        fetchAdminReservations('get_for_business')
            .then(r => {
                if (r) setReservations(r)
                if (r) setDisplayReservations(r)
            }).finally(() => setLoading(prev => ({...prev, fetch: false})))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        filterDisplayReservations()
        // eslint-disable-next-line
    }, [reservationStatus, onlyPending, reservations])

    const filterDisplayReservations = () => {
        setLoading(prev => ({...prev, filter: true}))
        if (reservationStatus === 'accepted') setDisplayReservations([...reservations.filter(r => ['pending', !onlyPending && 'accepted'].includes(r.display_status))])
        else setDisplayReservations([...reservations.filter(r => [!onlyPending && 'rejected'].includes(r.display_status))])
        setLoading(prev => ({...prev, filter: false}))
    }

    const deleteReservation = (id) => {
        destroyReservation(id).then(resp => {
            if (resp.status === 204) setReservations(reservations.filter(r => r.id !== id))
        })
        setDetailView(false)
    }

    const updateDate = (id, date, slot) => {
        if (JSON.stringify(slot) === JSON.stringify({})) return
        postReservation(`${id}/update_date/`, {date: date, slot: slot}).then(r => {
            setReservation(r)
            setReservations([r, ...reservations.filter(res => r.id !== res.id)])
        })
    }

    const findReservation = id => reservations.find(r => r.id === id)

    const accept = (id) => {
        fetchReservations(`${id}/accept/`)
            .then(updatedReservation => setReservations([...reservations.map(
                currentReservation => {
                    if (currentReservation.id === updatedReservation.id) return updatedReservation
                    else return currentReservation
                })
            ]))
        setDetailView(false)
    }

    const reject = (id) => {
        fetchReservations(`${id}/reject/`)
            .then(updatedReservation => setReservations([...reservations.map(
                currentReservation => {
                    if (currentReservation.id === updatedReservation.id) return updatedReservation
                    else return currentReservation
                })
            ]))
        setDetailView(false)
    }

    const create_invoice = (id) => {
        fetchReservations(`${id}/create_invoice/`)
            .then(updatedReservation => setReservations([...reservations.map(
                currentReservation => {
                    if (currentReservation.id === id) return {
                        ...currentReservation,
                        invoice_id: updatedReservation?.invoice_id
                    }
                    else return currentReservation
                })
            ]))
        setDetailView(false)
        snackbar.enqueueSnackbar('Számla elkészítve!', {variant: 'success'})
    }

    const download_invoice = (id) => {
        fetchBlob(`${id}/download_invoice/`)
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
        setDetailView(false)
    }

    const send_invoice = (id) => {
        fetchReservations(`${id}/send_invoice/`)
            .then(sent => snackbar.enqueueSnackbar('Számla kiküldve!', {variant: 'success'}))
        setDetailView(false)
    }

    const statusMap = {
        'accepted': 'elfogadott',
        'pending': 'elfogadásra vár...',
        'rejected': '',

    }

    const renderName = (params) => <Stack spacing={1} direction='row'>
        <Avatar className={params.row?.display_status}>{params.row?.last_name[0]}{params.row?.first_name[0]}</Avatar>
        <Stack spacing={0}>
            <span className='reservation-name'>{params.row?.name}</span>
            <span className={`reservation-${params.row?.display_status}`}>{params.row?.display_status !== 'rejected' && statusMap[params.row?.display_status]}</span>
        </Stack>
    </Stack>

    const renderDate = (params) => <Stack spacing={0}>
        <Typography variant='button' style={{color: '#000'}}
                    align='left'>{params.row?.start_time}-{params.row?.end_time}</Typography>
        <Typography variant='caption' style={{color: '#536B74'}} align='left'>{params.row?.date}</Typography>
    </Stack>

    const columns = [
        {field: 'name', headerName: 'Kliens neve', width: 200, renderCell: renderName},
        {
            field: 'service',
            headerName: 'Szolgáltatás',
            width: 200,
            renderCell: (params) => <Typography variant='button'>{params.row?.price?.service?.name}</Typography>,
            valueGetter: (params) => params.row?.price?.service?.name
        },
        {
            field: 'colleague',
            headerName: 'Kolléga',
            width: 130,
            renderCell: (params) => <Typography variant='caption' style={{color: '#536B74'}}>{params.row?.price?.colleague?.name}</Typography>,
            valueGetter: (params) => params.row?.price?.colleague?.name
        },
        {
            field: 'date',
            headerName: 'Időpont',
            type: 'dateTime',
            width: 130,
            renderCell: renderDate,
            valueGetter: (params) => `${params.row?.date} ${params.row?.start_time}`
        },
        {
            field: 'price',
            headerName: 'Ár',
            width: 130,
            type: 'number',
            renderCell: (params) => <Typography variant='button' style={{color: '#536B74'}}>{thousandSeparator(params.row?.price?.price) + ' Ft'}</Typography>,
            valueGetter: (params) => params.row?.price?.price
        },
        {
            field: 'manage',
            headerName: 'Kezelés',
            sortable: false,
            type: "actions",
            width: 200,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon className='icon-style'/>}
                    onClick={() => {
                        setReservation(findReservation(params.row?.id))
                        setDeleteOpen(true)
                    }}
                    label="Törlés"
                    showInMenu={false}/>,
                <GridActionsCellItem
                    icon={<KeyboardArrowRightRoundedIcon className='inverted-icon-style'/>}
                    onClick={() => {
                        setReservation(findReservation(params.row?.id))
                        setDetailView(true)
                    }}
                    label="Részletek"
                    showInMenu={false}/>]
        },
    ]

    const dialogActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            deleteReservation(reservation?.id)
        }} endIcon={<DeleteIcon/>}>Foglalás törlése</Button>
    </>

    const modifyActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setModifyTimeOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setModifyTrigger((modifyTrigger) => modifyTrigger + 1)
            setModifyTimeOpen(false)
        }} endIcon={<CalendarTodayRoundedIcon/>}>Időpont módosítása</Button>
    </>

    return <AuthBase
        label='Foglalások'
        buttons={buttons}
        filterButtons={filterButtons}
        filterButtonsMobile={filterButtonsMobile}
    >
        <div className="reservations-main">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2, // Optional: Adds spacing between items
                    marginBottom: 2
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1, // Optional: Adds spacing between items
                }}>
                    <IconButton
                        variant='outlined'
                        onClick={() => setListView(!listView)}
                    >
                        {listView ? <CalendarTodayRoundedIcon sx={{color: '#5EB885'}}/> :
                            <FormatListBulletedRoundedIcon sx={{color: '#5EB885'}}/>}
                    </IconButton>

                    <Typography
                        variant='subtitle2'
                        style={{color: '#536B74'}}
                    >
                        {displayReservations.length} db
                    </Typography>
                </Box>

                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{color: '#6F8E9A'}}
                            checked={onlyPending}
                            onChange={(event) => setOnlyPending(event.target.checked)}
                        />
                    }
                    label={
                        <Typography variant='caption' color='#6F8E9A'>
                            csak elfogadásra várók
                        </Typography>
                    }
                />
            </Box>

            {
                listView ?
                    <DinoDataGrid columns={columns} rows={displayReservations}/>
                    :
                    <div className='white-card-calendar'>
                        <Calendar items={displayReservations.map(r => ({
                            onClick: () => {
                                setReservation(r)
                                setDetailView(true)
                            },
                            ...r
                        }))}
                        />
                    </div>
            }
        </div>

        <DetailDrawer open={detailView} setOpen={setDetailView}>
            <ReservationDetail
                r={reservation}
                close={() => setDetailView(false)}
                accept={accept}
                destroy={() => setDeleteOpen(true)}
                decline={reject}
                modify={() => setModifyTimeOpen(true)}
                create_invoice={() => create_invoice(reservation?.id)}
                download_invoice={() => download_invoice(reservation?.id)}
                send_invoice={() => send_invoice(reservation?.id)}/>
        </DetailDrawer>

        <DinoDialog title='Biztos törölni szeretnéd az alábbi foglalást?'
                    subtitle='Csak abban az esetben törölj időpontot ha  azt a klienssel előre leegyeztetted!'
                    open={deleteOpen}
                    handleClose={() => setDeleteOpen(false)}
                    actions={dialogActions}>
            <DialogDetail r={reservation}/>
        </DinoDialog>

        <DinoDialog open={newReservationOpen}
                    title='Új foglalás hozzáadása'
                    subtitle=''
                    handleClose={() => setNewReservationOpen(false)}>
            <ReservationProvider setNewReservationOpen={setNewReservationOpen}
                                 reservations={reservations}
                                 setReservations={setReservations}>
                <ReserveBase/>
            </ReservationProvider>
        </DinoDialog>

        <DinoDialog open={modifyTimeOpen}
                    title='Időpont módosítása'
                    subtitle='Válassz időpontot!'
                    actions={modifyActions}
                    handleClose={() => setModifyTimeOpen(false)}>
            <SelectOnlyDate reservationData={reservation} modifyTrigger={modifyTrigger} updateDate={updateDate}/>
        </DinoDialog>

        <Loading isLoading={Object.values(loading).some(value => value)}/>

        <SpeedDial
            ariaLabel="Foglalások kezelése"
            sx={{display: {sm: 'flex', md: 'none'}, position: 'fixed', bottom: 16, right: 16}}
            icon={<SpeedDialIcon style={{color: '#fff'}}/>}
        >
            {buttons?.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.props?.endIcon || action.props?.startIcon}
                    onClick={action.props?.onClick}
                    tooltipTitle={action.name}
                />
            ))}
        </SpeedDial>

    </AuthBase>
}

export default Reservations